// 判断手机 - ios/andriod
function isIOS() {
  const u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
}

/**
  * @description: 键盘弹起，输入框被遮挡
  */
function judgeInput() {
  if (isIOS()) {
    const fn = function () {
      if (
        document.activeElement.tagName === 'INPUT' ||
        document.activeElement.tagName === 'TEXTAREA'
      ) {
        setTimeout(function () {
          document.documentElement.scrollTop = document.body.scrollHeight;
        }, 50);
      }
    }
    window.removeEventListener('focusin', fn)
    window.addEventListener('focusin', fn);
  } else {
    const fn = function () {
      console.log('resize')
      if (
        document.activeElement.tagName === 'INPUT' ||
        document.activeElement.tagName === 'TEXTAREA'
      ) {
        setTimeout(function () {
          document.activeElement.scrollIntoView();
        }, 50);
      }
    }
    window.removeEventListener('resize', fn);

    window.addEventListener('resize', fn);
  }
}
function androidInput() {
  const input = document.getElementById('input');
  let fn = function () {
    setTimeout(function () {
      console.log('focus')
      if (input) {
        input.scrollIntoView(false);
      }
    }, 300);
  }
  input.removeEventListener('focus', fn)
  input.addEventListener('focus', fn);
}
export {
  isIOS,
  judgeInput,
  androidInput
}