import { defineStore } from 'pinia';
import {getUserInfo} from '@/view/home/api'
export const useUserStore = defineStore('user           ', {
  state: () => {
    return { 
        showSide:false,
        userInfo:{}
     };
  },
  // 也可以这样定义
  // state: () => ({ count: 0 })
  actions: {
    getUser(){
      setTimeout(() => {
        if(window.location.href.indexOf('/home') > -1 || window.location.href.indexOf('/message') > -1){
          getUserInfo().then(res=>{
            this.userInfo = res.data
          })
        }
      }, 300);
    }
  },
});
