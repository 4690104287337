import RSA from './rsa.js';
let CryptoJS = require('./crypto-js.js');
var RSA_PUBLIC =
  '-----BEGIN PUBLIC KEY-----\
    MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgGj8WNUSzCn0rHqfIXk9XPdJp60u\
    JnM+mVZVxY1lZmUCVA7t3eJZ0R0z1hUHkVb51eryDZcsz0QLSav3cmQv00ullK18\
    8aOs2SXZe6rcQf6XmOsVBqgADkrN+WePZJmb5Fr0NUkQ/sr7+R71cDZ87Y9QKm99\
    8BFOiWoEGxWWvDsTAgMBAAE=\
        -----END PUBLIC KEY-----';
// window.getGiftCallBack = function (getAppData) {
//   // store.dispatch('getAppId',getAppData)
// }

import axios from 'axios';
import Qs from 'qs';
var surl = window.location.href;
if (location.hostname == 'localhost') {
  surl =
    'http://aidev.lanlingcb.com/?user_id=20986&version=2.4.2&os=iOS&token=bearer%20bd80e7b4-785e-45c2-8e94-14c6f5f788f3#/home';
}
var obj = {};
var urls = surl.substr(surl.indexOf('?') + 1).split('&');
for (var i = 0; i < urls.length; i++) {
  var temp = urls[i].split('=');
  obj[temp[0]] = temp[1];
}
// console.log(obj, '888')
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// 设置请求超时时间
axios.defaults.timeout = 30000;

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

let token = obj.token;

// 响应拦截
axios.interceptors.response.use(
  (response) => {
    // console.log(response,'状态码')
    // 对响应数据做点什么
    if (response.status === 200) {
      //响应成功后
      if (response.headers['token']) {
        //获取响应头里面的数据，**Authorization根据你响应头里面的数据获取，并不是唯一值**
        sessionStorage.setItem('token', response.headers['token']);
      }
      return Promise.resolve(response);
    } else if (response.data.code === 401) {
      alert('请重新登录');
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    // 对响应错误做点什么
    if (error) {
      switch (error.code) {
        case 401:
          alert('请重新登录');
      }
    }
    return Promise.reject(error.data);
  }
);

// 封装get方法和post方法

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        // headers: {
        //   Authorization:  "Bearer " + sessionStorage.getItem('token')
        // }
      })
      .then((res) => {
        resolve(res.data);
        //  Loading.service(true).close();
        //  Message({message: '请求成功', type: 'success'});
      })
      .catch((err) => {
        reject(err.msg);
        //  Loading.service(true).close();
        // Message({ message: err.msg, type: "error" });
      });
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post({ url, data = {} }) {
  let formData = new FormData();
  Object.assign(data, obj);
  console.log(data,obj,'ppppppppppppppppppppp')
  // 要加密（RSA）的随机字符串privateKey（随机生成）
  var timestamp = new Date().getTime();
  var randomStr = CryptoJS.MD5(timestamp.toString()).toString();
  var privateKey = RSA.encrypt(randomStr, RSA.getPublicKey(RSA_PUBLIC));
  axios.defaults.headers.post['privateKey'] = privateKey;
  var a = Date.now();
  if (window.location.href.indexOf('/wechat') > -1) {
    sessionStorage.setItem('postId',obj.postId)
    data.accessType = 4
    data.url = window.location.href
    var encrypt = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      CryptoJS.enc.Utf8.parse(randomStr),
      {
        iv: CryptoJS.enc.Utf8.parse(randomStr.substr(0, 16)),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    var sign = encrypt.toString(); //加密后的数据
    formData.append('sign', sign);
  } else {
    // 要加密（RSA）的随机字符串privateKey（随机生成）
    axios.defaults.headers.post['token'] = decodeURIComponent(token);
    axios.defaults.headers.post['version'] = obj.version;
    let ocj = {
      privateKey: privateKey,
      sendTime: a,
      user_id: obj.user_id,
    };
    Object.assign(data, ocj);
    var encrypt = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      CryptoJS.enc.Utf8.parse(randomStr),
      {
        iv: CryptoJS.enc.Utf8.parse(randomStr.substr(0, 16)),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    var sign = encrypt.toString(); //加密后的数据
    formData.append('sign', sign);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, formData)
      .then((res) => {
        // console.log('请求地址', url, '参数', data, '结果：', res.data);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.msg);
      });
  });
}

/**
 * post方法，参数序列化
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function qspost(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, Qs.stringify(params))
      .then((res) => {
        resolve(res.data);
        //  Loading.service(true).close();
        //  Message({message: '请求成功', type: 'success'});
      })
      .catch((err) => {
        reject(err.msg);
        // Loading.service(true).close();
        // Message({ message: err.msg, type: "error" });
      });
  });
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then((res) => {
        resolve(res.data);
        // Loading.service(true).close();
        //  Message({message: '请求成功', type: 'success'});
      })
      .catch((err) => {
        reject(err.msg);
        // Loading.service(true).close();
        // Message({ message: err.msg, type: "error" });
      });
  });
}

/**
 * delete
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function deletefn(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, params)
      .then((res) => {
        resolve(res.data);
        // Loading.service(true).close();
        //  Message({message: '请求成功', type: 'success'});
      })
      .catch((err) => {
        reject(err.data);
        // Loading.service(true).close();
        // Message({ message: err.msg, type: "error" });
      });
  });
}
