import { createRouter, createWebHashHistory } from 'vue-router';
import { useMessageStore } from '@/stores/message.js';
let messageStore
setTimeout(()=>{
  messageStore = useMessageStore()
},100)
const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/home'),
    meta: {
      title: '首页',
    },
  },
  {
    name: 'message',
    path: '/message',
    component: () => import('./view/home/message.vue'),
    meta: {
      title: '会话',
    },
  },
  {
    name: 'vip',
    path: '/vip',
    component: () => import('./view/vip/vip.vue'),
    meta: {
      title: '会员中心',
    },
  },
  {
    name: 'record',
    path: '/record',
    component: () => import('./view/vip/record.vue'),
    meta: {
      title: '会员中心',
    },
  },
  {
    name: 'memberDetail',
    path: '/memberDetail',
    component: () => import('./view/vip/vip-detail.vue'),
    meta: {
      title: '会员详情',
    },
  },
  {
    name: 'wechat',
    path: '/wechat',
    component: () => import('./view/wechat/index.vue'),
    meta: {
      title: '溜溜壶',
    },
  },
  // {
  //   name: 'test',
  //   path: '/test',
  //   component: () => import('./view/test.vue'),
  //   meta: {
  //     title: '与鸿蒙交互',
  //   },
  // },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
    if(from.name === 'message'){
      messageStore.clear()
    }
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
