import { defineStore } from 'pinia';

export const useMessageStore = defineStore('message', {
  state: () => {
    return { sendMessage: '', status: 'init', title: '',answerId:'' };
  },
  // 也可以这样定义
  // state: () => ({ count: 0 })
  actions: {
    clear(){
      this.title = ''
      this.answerId = ''
    }
  },
});
