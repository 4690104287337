<template>
    <div class="item" @click="goMessage">
        <img src="@assets/msg.png" alt="">
        <div v-if="status != 'edit'" class="title van-ellipsis">
            {{ title }}
        </div>
        <div class="title" @click.stop v-else>
            <input class="edit-input"  v-model="title">
        </div>
        <div class="rt" @click.stop>
            <img v-if="status == 'default'" src="@assets/setting.png" alt="" @click="handleStatus('default')">
            <img v-if="status == 'select'" src="@assets/edit.png" alt="" @click="handleStatus('edit')">
            <img v-if="status == 'select'" src="@assets/delete.png" alt="" @click="handleDelete()">
            <img v-if="status == 'edit'" src="@assets/confirm.png" alt="" @click="handleConfirm">
            <img v-if="status == 'edit'" src="@assets/close.png" alt="" @click="handleStatus('close')">
        </div>
    </div>
</template>

<script setup>
import { showConfirmDialog } from 'vant';
import { defineProps, ref, defineEmits } from 'vue'
import { deleteAnswer, changeTitle } from './api.js'
import { useRouter } from 'vue-router'
import {useMessageStore} from '@/stores/message.js'
import {useUserStore} from '@/stores/user.js'
const messageStore = useMessageStore()
const userStore = useUserStore()
const props = defineProps(['data'])

const router = useRouter()
const status = ref('default')
const emits = defineEmits(['update'])
const title = ref(props.data.answerTitle)
function goMessage() {
    router.push({
        name: 'message',
        query: {
            id: props.data.answerId,
            title: props.data.answerTitle
        }
    })
    userStore.showSide = false
    
    messageStore.title = props.data.answerTitle
    messageStore.answerId = props.data.answerId
}
function handleStatus(type) {
    if (type === 'default') {
        status.value = 'select'
    }
    if (type === 'edit') {
        status.value = 'edit'
    }
    if (type === 'close') {
        title.value = props.data.answerTitle
        status.value = 'default'

    }
}
function handleConfirm() {
    console.log(title.value)
    changeTitle({
        answerId: props.data.answerId,
        answerTitle: title.value
    }).then(res => {
        if (res.code === 1) {
            status.value = 'default'
            console.log(messageStore.answerId)
            if(props.data.answerId==messageStore.answerId){
                messageStore.title = title.value
            }
            emits('update')
        }
    })
}
function handleDelete() {
    showConfirmDialog({
        title: '提示',
        message: '确认删除对话？',

    }).then(res => {
        if (res === 'confirm') {
            deleteAnswer({ answerId: props.data.answerId }).then(() => {
                emits('update')
            })
        }
    })
}
</script>

<style lang="less" scoped>
.item {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 44px;
    background: #222E48;
    border: 1px solid #486DC8;
    border-radius: 2px;
    line-height: 44px;
    font-size: 14px;
    color: #FFFFFF;
    padding: 0 12px;

    .edit-input {
        position: relative;
        z-index: 999;
        box-sizing: border-box;
        width: 100%;
        height: 24px;
        background: #E8ECF4;
        font-size: 14px;
        color: #1D1D2C;
        padding: 5px 8px;
        outline: none;
        line-height: normal;
transform: translateZ(0);
        border: none;
    }

    .title {
        flex: 1;
    }

    img {
        
        width: 16px;
        height: 16px;
    }
}

.rt {
    display: flex;
 

    img {
       
        padding: 4px;
        width: 16px;
        height: 16px;
    }
}
</style>