import { post } from '@/utils/http'
export const getRecommendList = function (data) {
    return post({
        url: "/app/xfai/selectAiRecommendList",
        data
    })
}
export const getUserInfo = function (data) {
    return post({
        url: "/app/xfai/selectUserMemberInfo",
        data
    })
}

export const addConversation = function (data) {
    return post({
        url: "/app/xfai/addMemberAnswer",
        data
    })
}

export const addMessageItem = function (data) {
    return post({
        url: "/app/xfai/addMemberChat",
        data
    })
}

