import { post } from '@/utils/http'
export const getRecommendList = function (data) {
    return post({
        url: "/app/xfai/selectAiRecommendList",
        data
    })
}
export const getUserRecommend = function (data) {
    return post({
        url: "/app/xfai/selectUserAiRecommendList",
        data
    })
}
export const getHistoryList = function (data) {
    return post({
        url: "/app/xfai/selectHistoryAnswerList",
        data
    })
}
export const deleteAnswer = function (data) {
    return post({
        url: "/app/xfai/deleteAnswerByAnswer",
        data
    })
}

export const changeTitle = function(data){
    return post({
        url: "/app/xfai/updateAnswerTitle",
        data
    })
}

export const getOpenMemberList = function (data) {
    return post({
        url: "/app/xfai/getOpenMemberList",
        data
    })
}