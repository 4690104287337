<template>
    <div>
        <van-overlay :show="userStore.showSide" @click="handleSide" :custom-style="{ background: 'rgba(0,0,0,0.3)' }">
        </van-overlay>

        <!-- 左滑进入 -->
        <transition name="van-slide-left">
            <div v-if="userStore.showSide" class="side-bar" @click.stop>
                <div class="user-info">
                    <!-- {{userInfo.photo}} -->
                    <div class="user-top">
                        <div class="avatar">
                            <img v-if="userInfo.photo" :src="userInfo.photo" alt="">
                            <img v-else src="@assets/avatar.png" alt="">
                        </div>
                        <div class="rt">
                            <div class="row-1">
                                <div class="name">{{ userInfo.nickName }}</div>
                                <div class="btn" v-if="userInfo.memberLevel === 0" @click="goVip">立即开通</div>
                                <img src="@assets/vip.png"  style="width:24px" v-if="userInfo.memberLevel >0"  />
                            </div>
                            <div class="row-2">
                                <div class="lt" v-if="userInfo.memberLevel === 0">升级会员，每日使用次数最高{{userInfo.memberNumNow}}次</div>
                                <div class="lt" v-if="userInfo.memberLevel >0" @click="goMemberDetail">
                                    详情 <van-icon name="arrow" />
                                </div>
                                <div class="rt" v-if="userInfo.memberLevel >0">
                                    <div class="btn" @click="goVip">立即续费</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <div>今日使用：{{ userInfo.useNumNow }}/{{ userInfo.useNum }}次</div>
                        <div class="jv" @click.stop="goJubao">
                            <img src="@assets/jubao.png" alt="">
                            <div>举报</div>
                        </div>

                        <!-- <div>本月累计：{{ userInfo.useNumMonthTotal }}/{{ userInfo.useNumMonth }}次</div> -->
                    </div>
                </div>
                <div>
                    <van-tabs line-width="120" title-active-color="#fff" title-inactive-color="#777797" color="#fff"
                        v-model:active="active" background="transparent">
                        <van-tab>
                            <template #title>
                                <div class="tab-1">
                                    常用对话
                                    <div class="replay" @click="refresh">
                                        <van-icon :class="showRefresh ? 'animation' : ''" class="refresh" color="#5381FF"
                                            name="replay" />
                                        换一批
                                    </div>
                                </div>
                            </template>

                        </van-tab>
                        <van-tab>
                            <template #title>
                                <div class="tab-2">
                                    历史对话
                                </div>
                            </template>
                        </van-tab>
                    </van-tabs>
                </div>
                <div class="recommend-list" v-if="active == 0">
                    <div>
                        <div class="recommend-item" v-for="item in listData.recommendList" :key="item.recommendId"
                            @click="selectRecommend(item.recommendTitle)">{{
                                item.recommendTitle }}
                        </div>
                    </div>
                </div>
                <div class="history-list" v-if="active == 1">
                    <van-list @load="getHistory('load')" offset="50" v-model:loading="loading" :finished="finished">
                        <history-item class="history-item" v-for="item in historyList" :key="item.answerId"
                            :data="item" @update="getHistory"></history-item>
                    </van-list>
                </div>
                <div class="bottomData2">模型名称：开水壶文本生成算法</div>
                <div class="bottomData">网信算备469023186731101240011号</div>
            </div>
        </transition>
    </div>
</template>

<script setup>
let tokenUrl = null
setTimeout(()=>{
    tokenUrl = sessionStorage.getItem('tokenUrl')
},500)
import { getRecommendList, getHistoryList } from './api'
import { useUserStore } from '@/stores/user.js'
import { ref, onMounted, reactive, computed, watch } from 'vue'
import HistoryItem from './history-item.vue'
import { useRouter } from 'vue-router'
import { useMessageStore } from '@/stores/message'
const messageStore = useMessageStore()

const router = useRouter()
const userStore = useUserStore()  //pinia
const userInfo = computed(() => {//用户信息
    return userStore.userInfo
})

const showRefresh = ref(false)
const active = ref(0)  //tab栏
const historyList = ref([])
const listData = reactive({
    recommendList: [],
})
const loading = ref(false)
onMounted(() => {
    if(window.location.href.indexOf('/home') > -1){
        getList()
        getHistory()
    }
   
})

const showSide = computed(() => {
    return userStore.showSide
})
watch(showSide, (val) => {
    if (val) {
        getList()
        getHistory()
        userStore.getUser()
    }
})


const recommendPage = ref({ pageSize: 10, pageNum: 1 })

const historyPage = ref({ pageSize: 10, pageNum: 1 })
const finished = ref(false)
function getList() {
    getRecommendList(recommendPage.value).then(res => {
        listData.recommendList = res.data
    })
}
function goJubao(){
    window.location.href = tokenUrl;
    localStorage.setItem('reloadPage', 1)
}
function getHistory(load) {
    if (load) {
        historyPage.value.pageNum++
    } else {
        historyPage.value.pageNum = 1

    }
    getHistoryList(historyPage.value).then(res => {

        loading.value = false
        if (load) {
            if (!res.data.length) {
                finished.value = true
                return
            }
            historyList.value = historyList.value.concat(res.data)
        } else {
            historyList.value = res.data
        }

    })
}
function handleSide() {
    userStore.showSide = false
}
function refresh() {
    if (active.value != 0) {
        return
    }
    showRefresh.value = true
    setTimeout(() => {
        showRefresh.value = false
    }, 1000)
    recommendPage.value.pageNum++
    getList()
}
function selectRecommend(text) {
    messageStore.answerId = ''
    messageStore.title = text
    userStore.showSide = false
    router.push({
        name: 'home',

    })
    setTimeout(() => {
        router.push({
            name: 'message',
            query: {
                text: text
            }
        })
    }, 200)

}

function goVip(){
    userStore.showSide = false
    router.push({
        name:'vip',
    })
}

function goMemberDetail(){
    userStore.showSide = false
    router.push({
        name:'memberDetail',
    })
}
</script>

<style lang="less" scoped>
.side-bar {
    background: #fff;
    position: absolute;
    left: 0;
    top: 88px;
    height: calc(100vh - 88px);
    width: 330px;
    z-index: 101;
    background: #1A1C33;
    border-radius: 0 0 64px 0;
    overflow: hidden;

    .user-info {
        box-sizing: border-box;
        margin: 0 auto;
        width: 297px;
        background: url(../../assets/userbg.png) no-repeat;
        background-size: 100% 100%;
        margin-top: 48px;
        padding: 20px 8px;
        .user-top {
            display: flex;

            .avatar {
                width: 48px;
                height: 48px;

                img {
                    border-radius: 50%;
                    width: 48px;
                height: 48px;
                }
            }

            >.rt {
                margin-left: 16px;
                flex: 1;

                .btn {
                    width: 64px;
                    height: 20px;
                    background-image: linear-gradient(90deg, #FDF2DE 0%, #FFC999 100%);
                    border-radius: 12px;
                    text-align: center;
                    line-height: 20px;
                    font-weight: 700;
                    font-size: 10px;
                    color: #702305;
                }

                .row-1 {
                    display: flex;
                    align-items: center;
                    >.name {
                        max-width: 200px;
                        font-weight: 700;
                        font-size: 16px;
                        color: #FFFFFF;
                        margin-right:8px;
                        // line-height: 16px;
                    }
                }

                .row-2 {
                    margin-top: 6px;
                    display: flex;
                    font-size: 12px;
                    color: #ffffff99;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }

        .bottom {
            padding: 4px 8px;
            height: 24px;
            background: #ffffff33;
            border-radius: 4px;
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            color: #ffffff99;
            .jv{
                display: flex;
                div{
                    margin-left: 6px;
                }
            }
        }
        img{
            width: 16px;
            height: 16px;
        }

    }
}

.tab-1 {
    display: flex;
    gap: 8px;

    .replay {
        color: #5381FF;
    }
}

.tab-2 {
    width: 120px;
    display: flex;
    justify-content: flex-end;
}

.recommend-list {
    height: calc(100vh - 380px);
    overflow-y: auto;
}

.history-list {
    height: calc(100vh - 380px);
    overflow-y: scroll;
}

.recommend-item {
    padding: 16px 0;
    font-size: 14px;
    color: #FFFFFF;
    border-bottom: 1px solid #34354C;
    margin: 0 16px;
}

.history-item {
    margin: 0 16px;
    margin-top: 16px;
}

.refresh {
    transform: rotate(0);

    &.animation {
        animation: refresh 1s;

    }
}

@keyframes refresh {
    0% {
        transform: rotate(0);

    }

    100% {
        transform: rotate(360deg);

    }
}
.bottomData{
    position: absolute;
    left: 0;
    bottom: 22px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 12px;
    color: #6F739C;
    width: 290px;
    text-align: center;
}
.bottomData2{
    position: absolute;
    left: 0;
    bottom: 40px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 12px;
    color: #6F739C;
    width: 290px;
    text-align: center;
}
</style>